import { FC } from 'react'
import { Modal } from '@mch-group/uikit-components'

import useResetPasswordCRMUser from './hooks'
import FormComponent from './form'

const ResetPasswordCRMUser: FC = () => {
  const { isPasswordModalOpen, labels, handleNewPasswordSubmitted, setIsPasswordModalOpen } = useResetPasswordCRMUser()
  if (isPasswordModalOpen) {
    return (
      <Modal
        show
        onHide={() => { setIsPasswordModalOpen(false) }}
        size='md'
      >
        <Modal.Header
          title={labels?.GeneratePasswordPopup.title || 'Create new password'}
          //@ts-ignore
          backButton=''
          backButtonText=''
          onBackButtonClick={() => { }}
          onHide={() => { setIsPasswordModalOpen(false) }}
        />
        <Modal.Body>
          <FormComponent
            //@ts-ignore
            labels={{ ...labels?.GeneratePasswordPopup, ...labels?.Access }}
            handleNewPasswordSubmitted={handleNewPasswordSubmitted}
          />
        </Modal.Body>
      </Modal>
    )
  } else {
    return null
  }
}

export default ResetPasswordCRMUser