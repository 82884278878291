import PropTypes from 'prop-types'
import { Form, Input, Grid } from 'semantic-ui-react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button } from '@mch-group/uikit-components'

import InputFeedback from '../../../Form/InputFeedback'
import validationConstants from '@constants/validation'

const ResetPasswordCRMUserForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  labels,
  className
}) => (
  <Form onSubmit={handleSubmit} loading={isSubmitting} className={className}>
    <Grid>
      <Grid.Column computer='6' mobile='12'>
        <Form.Field error={errors.newPassword && touched.newPassword}>
          <label htmlFor='newPassword'>{labels.passwordFieldLabel || 'New password'}</label>
          <Input
            type='password'
            id='newPassword'
            value={values.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.newPassword && touched.newPassword}
          />
          {errors.newPassword && touched.newPassword && <InputFeedback>{errors.newPassword}</InputFeedback>}
        </Form.Field>
      </Grid.Column>
      <Grid.Column computer='6' mobile='12'>
        <Form.Field error={errors.newPasswordConfirm && touched.newPasswordConfirm}>
          <label htmlFor='newPasswordConfirm'>{labels.confirmPasswordFieldLabel || 'Confirm password'}</label>
          <Input
            type='password'
            id='newPasswordConfirm'
            value={values.newPasswordConfirm}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.newPasswordConfirm && touched.newPasswordConfirm}
          />
          {errors.newPasswordConfirm && touched.newPasswordConfirm && (
            <InputFeedback>{errors.newPasswordConfirm}</InputFeedback>
          )}
        </Form.Field>
      </Grid.Column>
      <Grid.Column computer='6' mobile='12' className='mt-6'>
        <Button type='submit'>
          {labels.createButtonLabel || 'Create new password'}
        </Button>
      </Grid.Column>
    </Grid>
  </Form>
)

ResetPasswordCRMUserForm.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  labels: PropTypes.shape({
    confirmPasswordFieldLabel: PropTypes.string,
    createButtonLabel: PropTypes.string,
    passwordFieldLabel: PropTypes.string
  })
}

const FormComponent = withFormik({
  mapPropsToValues: () => ({
    newPassword: '',
    newPasswordConfirm: ''
  }),

  validationSchema: ({ labels }) =>
    Yup.object().shape({
      newPassword: Yup.string()
        .required(labels.fieldRequiredLabel)
        .min(12, labels.minCharactersPasswordErrorLabel)
        .matches(
          validationConstants.AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER,
          labels.uppercaseandminCharPasswordLabel)
        .matches(
          validationConstants.NON_ROMAN,
          labels.romamCharactersErrorLabel),
      newPasswordConfirm: Yup.string()
        .required(labels.fieldRequiredLabel)
        // @ts-ignore
        .oneOf([Yup.ref('newPassword'), null], 'Passwords don\'t match')
    }),
  //@ts-ignore
  handleSubmit: (values, { setSubmitting, isTCAccepted, props }) => {
    if (!isTCAccepted) {
      // setSubmitting(true)
      //@ts-ignore
      props.handleNewPasswordSubmitted(values)
      return
    }
    setSubmitting(false)
  },
  displayName: 'ResetPasswordCRMUserForm' // helps with React DevTools
  //@ts-ignore
})(ResetPasswordCRMUserForm)

export default FormComponent
