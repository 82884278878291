import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useMemo } from 'react'

// Extend Day.js with plugins
dayjs.extend(utc)
dayjs.extend(timezone)

const useCTABanner = (data) => {
  const dateFormat = 'DD/MM/YYYY'
  const [startDateYear, startDateMonth, closeDateYear, closeDateMonth] = useMemo(() => {
    const startYear = dayjs(data?.startDate, dateFormat).format('YYYY')
    const startMonth = dayjs(data?.startDate, dateFormat).format('MM')
    const closeYear = dayjs(data?.closeDate, dateFormat).format('YYYY')
    const closeMonth = dayjs(data?.closeDate, dateFormat).format('MM')

    return [startYear, startMonth, closeYear, closeMonth]
  }, [data?.closeDate, data?.startDate])

  // default date format
  let formattedDate = `${dayjs(data?.startDate, dateFormat).format('MMM D')} - ${dayjs(data?.closeDate, dateFormat).format('D')}, ${dayjs(data?.startDate, dateFormat).format('YYYY')}`

  if ((closeDateYear > startDateYear) || (closeDateYear === startDateYear && closeDateMonth > startDateMonth)) {
    formattedDate = `${dayjs(data?.startDate, dateFormat).format('MMM D, YYYY')} - ${dayjs(data?.closeDate, dateFormat).format('MMM D, YYYY')}`
  }

function isDateAfterCurrentUTC(targetStartDate, targetEndDate) {
    // Parse the provided date string
    const providedStartDate = dayjs.utc(targetStartDate, 'DD/MM/YYYY HH:mm');
    const providedEndDate = dayjs.utc(targetEndDate, 'DD/MM/YYYY HH:mm');

    // Get the current UTC date
    const currentUTCDate = dayjs.utc();

    // Compare the provided date with the current UTC date
    return (currentUTCDate.isAfter(providedStartDate) || currentUTCDate.isSame(providedStartDate)) && currentUTCDate.isBefore(providedEndDate)
}

const isVisible = isDateAfterCurrentUTC(data?.bannerVisibilityStartDate, data?.bannerVisibilityCloseDate)

  return {
    formattedDate,
    isVisible
  }
}

  export default useCTABanner