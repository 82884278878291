import { useEffect, useRef } from 'react'
import { getReferrer } from '@utils'

const useTagManager = (isVisible) => {
  let previousPath = useRef('')

  const sendDataLayer = (data) => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(data)
    }
  }

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        sendDataLayer({
          event: 'ticket_banner_displayed',
          pageLocation: {
            url: window.location.href,
            title: document.title
          },
          referrer: !getReferrer(document.referrer, previousPath) ? 'direct' : document.referrer
        })
      }, 5000)
    }
  }, [isVisible])

  return { sendDataLayer }
}

export default useTagManager