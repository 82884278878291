import { useEffect, useCallback, useState, useContext } from 'react'

import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { LoginContext } from '@providers/Login'

import { setUserAccessCookies, logger, getSiteLanguage } from '@utils'
import { User as UserService } from '@services'

import { useRouter } from 'next/router'


const useResetPasswordCRMUser = () => {
  // Contexts
  const { showTCModal } = useContext(LoginContext)
  // Labels
  const { labels } = useLabelsFromAPI(['GeneratePasswordPopup', 'Access'], getSiteLanguage())

  // States
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const router = useRouter()
  const { guid } = router.query

  const hasUserEverResetPassword = useCallback(async () => {
    setIsReady(true)
    if (typeof guid === 'string') {
      const result = await UserService.signInInfo(guid)
      // @ts-ignore User logged in
      if (result.data.hasPassword === false) {
        setIsPasswordModalOpen(true)
      }
    }
  }, [guid])

  const handleNewPasswordSubmitted = async (values) => {
    try {
      const allValues: Paths.DoGenerateNewPasswordUsingPOST.QueryParameters = { ...values }
      if (typeof guid === 'string') {
        allValues.crmGuid = guid
        const response = await UserService.generateNewPassword(allValues)
        if (response.ok) {
          //@ts-ignore
          setUserAccessCookies(response)
          setIsPasswordModalOpen(false)
          if (!(response.data && response.data.sessionSummary)) {
            throw new Error('session summary null')
          }
          if (!response.data.sessionSummary.termsAndConditionsAccepted) {
            showTCModal({
              onTCAcceptedCallback: async () => {
                window.location.href = '/dashboard/overview'
              }
            })
          } else {
            window.location.href = '/dashboard/overview'
          }
        }
      }
    } catch (e) {
      logger(e)
    }
  }

  useEffect(() => {
    if (typeof guid === 'undefined') {
      router.push({ pathname: '/' }, '/')
    }
  }, [router, guid])

  useEffect(() => {
    if (!isPasswordModalOpen && !isReady) {
      hasUserEverResetPassword()
    }
  }, [hasUserEverResetPassword, isPasswordModalOpen, isReady])

  return { isPasswordModalOpen, labels, hasUserEverResetPassword, handleNewPasswordSubmitted, setIsPasswordModalOpen }
}

export default useResetPasswordCRMUser
