import React, { FC } from 'react'
import { CustomLinkTag } from '@components'
import { CTABanner } from '@mch-group/uikit-components'
import useTagManager from './hooks/useTagManager'
import useCTABanner from './hooks/useCTABanner'

export interface TSBMProps {
  tsbm_grp1_tickets_banner: string
}

const TSBM: FC<TSBMProps> = (props) => {
  const parsedCmsData = props?.tsbm_grp1_tickets_banner && JSON.parse(String(props.tsbm_grp1_tickets_banner || {}))
  const { formattedDate, isVisible } = useCTABanner(parsedCmsData)
  const { sendDataLayer } = useTagManager(isVisible)

  return isVisible && parsedCmsData ? (
    <div>
      <CTABanner
        ctaLabel={parsedCmsData.ctaLabel}
        heading={parsedCmsData.showName}
        icon='ticket'
        link={parsedCmsData.ctaUrl}
        subheading={formattedDate}
        positioned
        linkRenderer={(link, children, linkTitle, ariaLabel, className) => (
          <CustomLinkTag
            url={link}
            title={linkTitle}
            aria-label={ariaLabel}
            className={className}
            onClick={() => sendDataLayer({
              event: 'ticket_banner_clicked',
              pageLocation: {
                url: window.location.href,
                title: document.title
              },
              landing: parsedCmsData.ctaUrl
            })}
          >
            {children}
          </CustomLinkTag>
        )}
      />
    </div>
  ) : null
}

export default TSBM
